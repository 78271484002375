 html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    box-sizing: border-box;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    text-decoration: none!important;
    color: #314814;
  }
  a:hover {
    color: #62af04;
  }
  input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px #fff inset; /* Change the color to your own background color */
    box-shadow: transparent;
    -webkit-text-fill-color: rgb(0, 0, 0);
  }
  body {
    background: #fff;
    color: #0B093B;
    font-family: 'Palanquin', sans-serif;
    
    overflow-x: hidden;
    font-size: 16px;
    font-weight: 400;
  }
  .container {
    width: 100%;
    max-width: 1320px;
    margin: 0 auto;
    padding: 0px 0px;
  }
  @media only screen and (max-width: 859px) {
    .container {
      padding: 0 0px;
    }
  }
  .regular {
    font-weight: 400!important;
  }
  
  .semiBold {
    font-weight: 600!important;
    color: #314814;
  }
  .semiBoldNetral {
    font-weight: 600!important;
    
  }

  .semiBoldWhite {
    font-weight: 600!important;
    color: #ffffff;
  }

  .Bold {
    font-weight: 700!important;
    
  }

  .PT-Sans{
    font-family: 'PT Sans', sans-serif;
  }

  .Palanquin{
    font-family: 'Palanquin', sans-serif;
  }

  .italicFont{
    font-style: italic;
  }

  .arrowIcon{
    font-size: 50px;
  }
  
  @media (max-width: 893px) {
    .arrowIcon {
      font-size: 40px;
    }
  }
  @media (max-width: 650px) {
    .arrowIcon {
      font-size: 30px;
    }
  }


  .quotesIcon{
    font-size: 80px;
    align-self: flex-start;
    color: #DA561D;
  }
  .extraBold {
    font-weight: 700!important;
    color: #314814;
  }
  .extraBoldOrange {
    font-weight: 700!important;
    color: #DA561D;
  }
  .extraBoldWhite {
    font-weight: 700!important;
    color: #ffffff;
  }

  /* HELLPERS */
  .textCenter {
    text-align: center
  }
  .animate {
    transition: 0.3s ease;
  }
  .pointer {
    cursor: pointer;
  }
  .relative {
    position: relative;
  }
  .textCenter {
    text-align: center;
  }
  .textLeft {
    text-align: left;
  }
  .textRight {
    text-align: right;
  }
  .x100 {
    width: 100%;
  }
  .active {
    
    border-bottom: 2px solid #DA561D;
    color: #314814;
  }

  @media (max-width: 760px) {
    .active {
      background: #314814;
      border: none;
      color: #ffffff;
    }
  }

  .sidebarActive {
    border-bottom: 2px solid #DA561D;
    color: #314814;
  }

  .show {display:block;}
  
  .tag {
    padding: 5px 10px;
    margin: 10px 0;
  }
  .shadow {
    box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
    -webkit-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
    -moz-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
  }
  /* FLEXBOX */
  .flex {
    display: flex;
  }
  .flexSpaceCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flexNullCenter {
    display: flex;
    align-items: center;
  }
  .flexColumn {
    flex-direction: column;
  }
  .flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flexSpaceNull {
    display: flex;
    justify-content: space-between;
  }
  .flexRight {
    display: flex;
    justify-content: right;
    align-items: right;
  }

  .flexRightCenter {
    display: flex;
    justify-content: right;
    align-items: center;
  }

  /* FONT SIZES */
  .font11 {
    font-size: 0.688rem;
  }
  .font12 {
    font-size: 0.75rem;
  }
  .font13 {
    font-size: 0.813rem;
  }
  .font14 {
    font-size: 0.875rem;
  }
  .font15 {
    font-size: 0.938rem;
  }
  .font16 {
    font-size: 1.038rem;
  }
  .font18 {
    font-size: 1.185rem;
    line-height: 1.9rem;
  }
  .font20 {
    font-size: 1.25rem;
  }
  .font24 {
    font-size: 1.53rem;
  }
  .font25 {
    font-size: 1.563rem;
  }
  .font30 {
    font-size: 1.875rem;
  }
  .font40 {
    font-size: 2.9rem;
    line-height: 3.5rem;
  }
  .font60 {
    font-size: 3.75rem;
    line-height: 4.5rem;
  }
  @media only screen and (max-width: 859px) {
    .font11 {
      font-size: 0.688rem;
    }
    .font12 {
      font-size: 0.688rem;
    }
    .font13 {
      font-size: 0.75rem;
    }
    .font14 {
      font-size: 0.813rem;
    }
    .font15 {
      font-size: 0.813rem;
    }
    .font18 {
      font-size: 0.938rem;
    }
    .font20 {
      /*font-size: 1.125rem;*/
      font-size: 62,5%;
    }
    .font25 {
      font-size: 1.25rem;
    }
    .font30 {
      font-size: 1.5rem;
    }
    .font40 {
      font-size: 1.875rem;
    }
    .font60 {
      font-size: 2.8rem;
    }
    
  }
  /* BORDER RADIUS */
  .radius8 {
    border-radius: 1rem;
  }
  .radius10 {
    border-radius: 20rem;
  }
  .radius6 {
    border-radius: 0.6rem;
  }
  .radius7 {
    border-radius: 0.8rem;
  }
  /* COLORS */
  .plainColor{
    color: #333333;
  }
  .darkColor {
    color: #0B093B;
  }
  .purpleColor {
    color: #7620FF;
  }
  .orangeColor {
    color: #DA561D;
  }
  .lightColor {
    color: #F5F5F5;
  }
  .coralColor {
    color: #C4FFFF;
  }
  .greenColor {
    color: #314814;
  }
  .greyPlaceholdeColor {
    color: #AAAAAA;
  }
  .greyColor {
    color: #707070;
  }
  .whiteColor {
    color: #ffffff;
  }
  /* BACKGROUNDS */

  .gradient {
    background-color: rgb(49,72,20);background: linear-gradient(180deg, rgba(213,254,162,1) 10%, rgba(255,255,255,1) 90%)
  }

  .gradient2 {
    
    background: rgb(49,72,20);
    background: linear-gradient(180deg, rgba(49,72,20,1) 0%, rgba(255,255,255,0) 32%);
  }

  .gradientWhite {
    
    background: rgb(241,241,241);
background: linear-gradient(180deg, rgba(241,241,241,0) 25%, rgba(255,255,255,1) 90%);
  }


  .darkBg {
    background-color: #0B093B;
  }
  .purpleBg {
    background-color: #7620FF;
  }
  .orangeBg {
    background-color: #F2B300;
  }
  .lightBg {
    background-color: #F5F5F5;
  }
  .coralBg {
    background-color: #C4FFFF;
  }
  .greenBg {
    background-color: rgba(218, 246, 160, 1);
  }
  .greenBgClose {
    background-color: #A9CE79;
  }
  .darkGreenBg {
    background-color: #314814;
  }
  .greyBg {
    background-color: #707070;
  }
  .whiteBg {
    background-color: rgb(255, 255, 255);
  }
  .whiteTansBg {
    background-color: rgba(255, 255, 255, 0.459);;
  }

  .text-green{
    text-decoration-color: #314814;
  }

  .containerGreenBg {
    background-color: #A9CE79;
    width: 200px;
  }
  /* Add an active class to the active dropdown button */
.activeDropdown {
  background-color: green;
  color: white;
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
  display: none;
  background-color: #ffffff;
  padding-left: 8px;

}
.dropdown-container3 {
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
  display: none;
  background-color: #ffffff;
  padding-left: 8px;

}

.dropdown-content {
  
  
  text-decoration: none;
  display: block;
}

.dropdown-container2 {
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
  display: block;
  background-color: #ffffff;
  padding-left: 8px;

}

.sidenav {
  height: 200px;
  width: 200px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 20px;
}


/* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px;
}

.sidenav a,  .dropdown-btn {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  border: none;
  background: none;
  width:100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}

.dropdown-btn2 {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  border: none;
  background: none;
  width:100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}

  .primaryGreen {
    color: #314814;
  }
  .bgContact{
    
    background-image: url("/src/style/Group\ 44.png");
    width: 100%; 
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bgFoto{
    
    background-image: url("img_foto.png");
    width: 100%; 
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    
  }

  .bgFotoTeamSP{
    
    background-image: url("./ImgTentangKami/pohon\ webp\ 1.png");
    width: 100%; 
    height: auto;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    
    
  }

  .bgImgMisi1{
    
    background-image: url("./ImgTentangKami/KetahananPangan.png");
    width: 100%; 
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    
  }
  .bgImgMisi2{
    
    background-image: url("./ImgTentangKami/KebutuhanNutrisi.png");
    width: 100%; 
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    
  }

  .bgImgMisi3{
    
    background-image: url("./ImgTentangKami/Presisi&Efisien.png");
    width: 100%; 
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    
  }

  .bgHeader_tentangkami{
    
    background-image: url("TentangKami_header.png");
    width: 100%; 
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    
  }
  

  @media only screen and (max-width: 1200px){
    .bgContact{
      background-image: url("/src/style/Group\ 44.png");
      width: 100%; 
      height: auto;
      background-position: left;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .bg{
    
    background-image: url("/src/style/Desktop\ -\ 1\ \(2\).png");
    width: 100%; 
    height: auto;
    
    background-repeat: no-repeat;
    background-size: contain;
    
    
  }
  @media only screen and (max-width: 660px){
      .bg{
          background-image: url("/src/style/iPad\ mini\ 8.3\ -\ 1.png");
          
          height: auto;
          
          background-repeat: no-repeat;
          background-size: cover;
      }
    }

    @media  (min-width: 1240px){
      .bg{
        background-image: url("/src/style/Desktop\ -\ 2.png");
          
          height: auto;
          
          background-repeat: no-repeat;
          background-size: contain;
      }
    }
    @media (min-width: 1400px){
      .bg{
        background-image: url("/src/style/Desktop\ -\ 2.png");
          
          height: auto;
          background-position: top;
          background-repeat: no-repeat;
          background-size: cover;
      }
    }
    @media (min-width: 1600px){
      .bg{
        background-image: url("/src/style/Desktop\ -\ 2\ \(2\).png");
          
          height: auto;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
      }
    }
    @media only screen and (min-width: 1900px){
      .bg{
        background-image: url("/src/style/Desktop\ -\ 2\ \(3\).png");
          
          height: auto;
          
          background-repeat: no-repeat;
          background-size: cover;
      }

      .bgContact{
    
        background-image: url("/src/style/Group\ 44.png");
        width: 100%; 
        height: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        
        
      }
      
    }

    .video{
      filter: drop-shadow(0 2px 50px rgb(0 0 0 / 0.6));
      padding-top: 100px ;
      width: 100%;
  
    }
    
      @media (max-width: 1240px) {
        .video{
          width: 100%;
        }
      
      }
      @media (max-width: 860px) {
        .video{
          width: 100%;
        }
        
        
        
      }
      @media (max-width: 560px) {
        .video{
          width: 120%;
          height: auto;
        }
        
        
      }

    .Divbg{
      background: #ffffff;
    }
    .Divbg:hover{
      background:#bbe486;
    }
    .each-slide > div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      height: 300px;
    }
    
    .each-slide span {
      padding: 20px;
      font-size: 20px;
      background: #ff4081;
      text-align: center;
      color: white;
    }